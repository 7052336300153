<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级审批</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="班级编码:" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="selectDate.projectCode"
                placeholder="请输入班级编码"
                :disabled="disabled"
                size="small"
                clearable
              />
            </div>
            <div title="班级名称:" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="selectDate.projectName"
                placeholder="请输入班级名称"
                :disabled="disabled"
                size="small"
                clearable
              />
            </div>
            <div title="培训机构:" class="searchboxItem ci-full">
              <span class="itemLabel">培训机构:</span>
              <el-input
                v-model="selectDate.compName"
                placeholder="请输入培训机构"
                :disabled="disabled"
                size="small"
                clearable
              />
            </div>
            <div title="行政区划:" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="selectDate.areaId"
                :options="areatreeList"
                :props="propsarea"
                :disabled="disabled"
                clearable
                size="small"
              ></el-cascader>
            </div>
           
          </div>
          <div class="searchbox">
            <div title="班级状态:" class="searchboxItem ci-full">
              <span class="itemLabel">班级状态:</span>
              <el-select v-model="selectDate.projectState" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in classtatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <!-- <div title="培训类型:" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </div> -->
            <div title="负责人:" class="searchboxItem ci-full">
              <span class="itemLabel">负责人:</span>
               <el-input
                v-model="selectDate.projectUser"
                placeholder="请输入负责人"
                :disabled="disabled"
                size="small"
                clearable
              />
            </div>
             <div title="开班时间:" class="searchboxItem ci-full">
              <span class="itemLabel">开班时间:</span>
              <el-date-picker
                size="small"
                v-model="selectDate.dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                clearable
              ></el-date-picker>
            </div>
            <div>
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="250"
              />
              <el-table-column
                label="培训机构"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="250"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="140"
              />
              <el-table-column label="负责人" align="left" show-overflow-tooltip prop="projectUser" />
              <el-table-column
                label="联系电话"
                align="left"
                show-overflow-tooltip
                prop="projectUserPhone"
                width="100"
              />
              <el-table-column label="总课程" align="right" show-overflow-tooltip prop="courseNum" />
              <el-table-column label="总学时" align="right" show-overflow-tooltip prop="totalPeriod" />
              <!-- <el-table-column label="学员总数" align="left"show-overflow-tooltip prop="projectPeople"/> -->
              <el-table-column label="开始时间" align="left" show-overflow-tooltip prop="startDate" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.startDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="结束时间" align="left" show-overflow-tooltip width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.endDate | momentDate }}</span>
                </template>
              </el-table-column>

              <el-table-column label="班级状态" align="left" show-overflow-tooltip prop="projectState">
                <!-- <template slot-scope="scope">
                  <span>{{ $setDictionary("PROJECTSTATE", scope.row.projectState) }}</span>
                </template> -->
                 <template slot-scope="scope">
                      {{getclassstatusList(scope.row.projectState)}}
                  </template>
              </el-table-column>

              <el-table-column label="操作" align="center" width="170px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    style="padding:0 5px"
                    type="text"
                    size="mini"
                    @click="
                      seeDetails(scope.row.projectId, scope.row.projectState, scope.row.projectSource,scope.row.auditState)
                    "
                  >审核详情</el-button>
                  <el-button
                    style="padding:0 5px"
                    type="text"
                    size="mini"
                    :disabled="
                      scope.row.projectState != '50' &&
                        scope.row.projectState != '60'
                    "
                    @click="goclassUser(scope.row.projectId)"
                  >班级学员详情</el-button>
                  <!-- <el-button
                   style="padding:0 5px"
                    type="text"
                    size="mini"
                    :disabled="
                      scope.row.projectState != '50' &&
                        scope.row.projectState != '60'
                    "
                    @click="open(scope.row.projectId, scope.row.userId)"
                    >班级抽查</el-button
                  >-->
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="班级抽查"
      :visible.sync="dialogCert"
      width="50%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 800px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment, { duration } from "moment";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "SupervisionEnd/classCheckList",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      params: {},
      ruleForm: {
        Trainingtype: ""
      },
      selectDate: {
        projectCode: "",
        projectName: "", //班级名称
        trainTypeId: "",
        areaId: "", //行政区域
        projectState: "", //状态
        dateRange: [], //创建时间
        compName:'',
        pageNum: 1,
        pageSize: 10
      },
      areatreeList: [],
      classtatusList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      dialogCert: false
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.getclasstatusList();
  },
  methods: {
     getclassstatusList(value) {
      let classstatuslist= [
        {
          label: "未申请",
          value: "10"
        },
         {
          label: "待审核",
          value: "25"
        },
         {
          label: "已通过",
          value: "30"
        },
         {
          label: "未通过",
          value: "40"
        },
         {
          label: "培训中",
          value: "50"
        },
         {
          label: "培训结束",
          value: "60"
        },
      ]
      for(let i in classstatuslist){
        if(value==classstatuslist[i].value){
          return classstatuslist[i].label
        }
      }
      
    },
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
    },
    clearParams() {
      this.params = {};
    },
    getSwitch(value) {
      this.$post("/biz/project/updateClassHourCertificate", {
        projectId: value
      }).then(re => {
        this.doFetch({
          url: "/biz/project/businessListPage",
          params: this.selectDate
        });
      });
    },
    getData(pageNum = 1) {
      const params = {
        projectCode: this.selectDate.projectCode,
        projectName: this.selectDate.projectName, //班级名称
        trainTypeId: this.selectDate.trainTypeId,
        areaId: this.selectDate.areaId, //行政区域
        projectState: this.selectDate.projectState, //状态
        dateRange: this.selectDate.dateRange,
        pageSize: this.pageSize,
        projectUser:this.selectDate.projectUser
      };
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.selectDate.compName) {
        params.compName = this.selectDate.compName;
      }
      this.doFetch({
        url: "/gov/project/auditListPage",
        params: {
          ...params
        },
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/gov/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getclasstatusList() {
      const classtatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [{ value: "", label: "全部" }];
      for (const key in classtatuslist) {
        if (key != "10" && key != "20") {
          // &&key != "20"
          list.push({
            value: key,
            label: classtatuslist[key]
          });
        }
      }
      this.classtatusList = list;
    },

    //详情
    seeDetails(projectId, projectState, projectSource,auditState) {
      this.$router.push({
        path: "/web/SupervisionEnd/classCheckDetail",
        query: {
          id: projectId,
          projectState: projectState,
          projectSource,
          auditState,
          needHold2: true
        }
      });
    },
    //跳转到学员详情
    goclassUser(projectId) {
      // this.$router.push({
      //   path: "/web/StudentLearningRecord",
      //   query: {
      //     projectId: projectId,
      //      needHold2: true
      //   },
      // });
      this.$router.push({
        name: "StudentLearningRecord",
        query: {
          projectId: projectId,
          needHold: true
        }
      });
    },
    //班级抽查
    open(projectId, userId) {
      let parma = {
        projectId: projectId,
        userId: userId
      };
      this.$post("/biz/project/spotCheckReport", parma).then(res => {
        if (res.status == 0) {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(res.message, "#pdf-cert");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    editTestPaper(projectId, affirm) {
      this.$router.push({
        path: "/web/addClassCtr",
        query: {
          projectId,
          stu: "edit",
          affirm
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 55;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    }
  },
  beforeRouteLeave: resetKeepAlive
  // watch: {
  //   $route: {
  //     handler: function(val, oldVal) {
  //       if (JSON.stringify(val.params) != "{}"||JSON.stringify(val.query) != "{}") {
  //         val.meta.level = 2;
  //       } else {
  //         if (
  //           val.path == "/web/ViewLearningRecords" &&
  //           oldVal.path == "/web/StudentLearningRecord"
  //         ) {
  //           val.meta.level = 2;
  //           oldVal.meta.level = 1;
  //         }
  //         // else {
  //         //   val.meta.level = 1;
  //         //   oldVal.meta.level = 2;
  //         // }
  //         val.meta.level = 1;
  //       }

  //       console.log(val.meta.level, "新页面的级别");
  //       console.log(oldVal.meta.level, "旧页面的级别");
  //     }
  //   }
  // }
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.searchbox {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
